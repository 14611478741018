// import './_vendor';
// import vars from './_vars';
// import './_functions';
// import './_components';

import AOS from 'aos'; 
import { tns } from "tiny-slider/src/tiny-slider";
import { bindTabs } from "./_tabs";

AOS.init({
  once: true
});

window.addEventListener('load', AOS.refresh)

const hero = document.querySelector(".hero__slider");
const programsList = document.querySelector(".programs__list1");
const podcastList = document.querySelector(".podcasts__list");
const platformList = document.querySelector(".platform__list");

const controlContainer = document.querySelector("#custom_controlsContainer");
const prevBtn = document.querySelector("#prev");
const nextBtn = document.querySelector("#next");

const controlContainer2 = document.querySelector("#custom_controlsContainer2");
const prevBtn2 = document.querySelector("#prev2");
const nextBtn2 = document.querySelector("#next2");

const controlContainer3 = document.querySelector("#custom_controlsContainer3");
const prevBtn3 = document.querySelector("#prev3");
const nextBtn3 = document.querySelector("#next3");


if (hero) {
  tns ({
    container: hero,
    autoplay: true,
    autoplayTimeout: 5000,
    mode : 'gallery',
    loop: true,
    mouseDrag: false,
    controls: false,
    nav: false
  });
}

if (programsList) {
  tns({
    container: programsList,
    mouseDrag: true,
    controls: true,
    loop: true,
    controlsContainer: controlContainer,
    prevButton: prevBtn, 
    nextButton: nextBtn,
    items: 1,
    nav: true,
    navPosition: 'bottom',
    center: true,
    responsive: {
      768: {
        nav: false,
        items: 2,
        gutter: 24,
        center: false,
        autoWidth: false,
        fixedWidth: 300,
        // startIndex: 2,
      },
    }
  })
}

if (podcastList) {
  tns({
    container: podcastList,
    mouseDrag: true,
    controls: true,
    loop: true,
    controlsContainer: controlContainer2,
    prevButton: prevBtn2, 
    nextButton: nextBtn2,
    items: 1,
    nav: true,
    navPosition: 'bottom',
    center: true,
    responsive: {
      768: {
        nav: false,
        items: 2,
        gutter: 24,
        center: false,
        fixedWidth: 300,
        // startIndex: 2,
      },
    }
  })
}

if (platformList) {
  tns({
    container: platformList,
    slideBy: 1,
    items: 1,
    mouseDrag: true,
    controls: true,
    loop: true,
    controlsContainer: controlContainer3,
    prevButton: prevBtn3, 
    nextButton: nextBtn3,
    nav: true,
    navPosition: 'bottom',
    center: true,
    responsive: {
      320: {
        // items: 1,
      },
      768: {
        // items: 3,
        center: false,
        gutter: 24,
        nav: false,
        fixedWidth: 300,
      },
    },
  });
}

bindTabs();


const progress = document.querySelector('.player__range input');

if (progress) {
  progress.addEventListener('input', function() {
    const value = this.value;
    this.style.background = `linear-gradient(to right, #fff 0%, #fff ${value}%, rgba(27, 25, 52, 0.8) ${value}%, rgba(27, 25, 52, 0.8) 100%)`
  })
}


const supportForms = document.querySelectorAll('.support__form');
const supportTabs = document.querySelectorAll('.support__tab button');

const mediaQuery = window.matchMedia('(max-width: 767px)')

if (mediaQuery.matches) {

  supportForms.forEach((item) => {
    item.classList.add('hidden');
  });

  supportTabs.forEach((item) => {
    item.classList.remove('active');
  });

}
  

const targetAnimation = document.querySelector('.platform');

if (targetAnimation) {

  targetAnimation.classList.remove('_animation');
  
  
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        targetAnimation.classList.add('_animation');
        return;
      }
  
      // targetAnimation.classList.remove('_animation');
    });
  });
  
  observer.observe(document.querySelector('.platform'));

}