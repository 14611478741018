// Tabs
const tabClick = ({ target }) => {
  const {
    dataset: { id = "" },
  } = target;

  document
    .querySelectorAll(".support__tab button")
    .forEach((t) => t.classList.remove("active"));
  target.classList.add("active");

  document
    .querySelectorAll(".support__form")
    .forEach((t) => t.classList.add("hidden"));
  document.querySelector(`#${id}`).classList.remove("hidden");
};

const bindTabs = () => {
  document.querySelectorAll(".support__tab button").forEach((tab) => {
    tab.addEventListener("click", tabClick);
  });
};

export { bindTabs };
